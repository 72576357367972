import Home from "./components/home";
import MainLayout from "../../components/layout";
import Parte3 from "./components/parte3";
import ParteDownload from "./components/parteDownload";
import Contato from "./components/contato";

export default function Main() {
  return (
    <Home />
    // <MainLayout>
    //   <Home />
    //   <Parte3 />
    //   <ParteDownload />
    //   <Contato />
    // </MainLayout>
  );
}
