export default function Home() {
  return (
    <div
      className="min-vh-100 d-flex align-items-center justify-content-center"
      style={{
        background: `url("/images/background.png") center center/cover no-repeat`,
        overflow: "hidden",
      }}
    >
      <div className="d-flex justify-content-center align-items-center">
        <div
          className="bg-light d-flex flex-column justify-content-center align-items-center"
          style={{
            borderRadius: "20px",
            backgroundColor: "rgba(255, 255, 255, 0.2)",
            padding: "100px 50px",
          }}
        >
          <img
            src="/images/logo-texto.png"
            width={100}
            height={100}
            alt="Logo"
            className="mb-4"
          />
          <p className="h2 mt-3 text-center">Está chegando a revolução do cashback...<br /> Aguarde...</p>
        </div>
      </div>
    </div>

    // <div style={{ overflowX: "hidden" }}>
    //   <div className="row g-5">
    //     <div className="col-lg-6 d-flex flex-column justify-content-center">
    //       <div className="px-5 mx-3">
    //         <img
    //           src="/images/bolinha.png"
    //           width={40}
    //           height={40}
    //           className="mx-5 mb-4"
    //         />
    //         <p className="font-big text-color-secondary">
    //         Transforme seus Cashbacks em DINHEIRO de forma RÁPIDA e SEGURA!
    //         </p>
    //         <p className="h3 text-color-secondary mt-4">
    //           Com o ChangeBack, você não só recebe cashbacks de suas lojas favoritas, mas também pode vendê-los e gerar uma GRANA EXTRA! Tudo isso de forma SIMPLES, RÁPIDA E SEGURA.
    //         </p>
    //         {/*
    //         <div className="d-flex align-items-center mt-4 mb-md-0">
    //           <a
    //             href="#"
    //             className="me-3 mb-3 mb-sm-0"
    //             id="appStoreUrl"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <img
    //               className="apple-store"
    //               height="55"
    //               width="178"
    //               src="/images/apple-store.png"
    //               alt="apple store"
    //             />
    //           </a>
    //           <a
    //             href="#"
    //             className="me-3 mb-3 mb-sm-0"
    //             id="appGoogleUrl"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //           >
    //             <img
    //               className="apple-google"
    //               height="55"
    //               width="178"
    //               src="/images/google-store.png"
    //               alt="apple store"
    //             />
    //           </a>
    //         </div>
    //         */}
    //       </div>
    //     </div>
    //     <div className="col-lg-6">
    //       <div className="d-flex justify-content-end">
    //         <img src="/images/banner-home.png" className="w-100" />
    //       </div>
    //       <div className="d-flex justify-content-end justify-content-lg-start">
    //         <img src="/images/bolinha.png" width={40} height={40} className="mx-5 mx-lg-0 mt-4 mt-lg-0" />
    //       </div>
    //     </div>
    //   </div>
    //   <hr
    //     className="w-100 border-none mt-5 "
    //     style={{ borderTop: "2px dashed #000" }}
    //   />
    // </div>
  );
}
