import "bootstrap/dist/js/bootstrap.bundle.min.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import AppRouter from "./routes";

function App() {
  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
